import React from "react"

import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import styled from "styled-components"

const Footer = () => {
  return (
    <footer className="page-footer">
      <Container>
        <Row className="mx-auto" style={{ paddingTop: "10px" }}></Row>
        <Row className="mx-auto">
          <Col sm={12} md={4}>
            <Row className="mx-auto">
              <Col>
                <StyledLink to="/AboutUs">About Us</StyledLink>
              </Col>
            </Row>
            <Row className="mx-auto">
              <Col>
                <StyledLink to="/RoadMap">Road Map</StyledLink>
              </Col>
            </Row>
            <Row className="mx-auto">
              <Col>
                <StyledLink to="/Contacts">Contacts</StyledLink>
              </Col>
            </Row>
          </Col>

          <Col sm={12} md={4}>
            <Row className="mx-auto">
              <Col>
                <StyledLink to="/blog" >
                  Blog
                </StyledLink>
              </Col>
            </Row>
            <Row className="mx-auto">
              <Col>
                <StyledLink to="/Faq">FAQ</StyledLink>
              </Col>
            </Row>
          </Col>

          <Col sm={12} md={4}>
            <StaticImage
              src="../assets/images/SPAPILogo.png"
              alt="azszero"
              placeholder="tracedSVG"
              layout="constrained"
              width={200}
            ></StaticImage>
          </Col>
        </Row>

        <Row className="mx-auto" style={{ fontSize: "smaller" }}>
          <Col xm={6} style={{ textAlign: "left" }}>
            <p>
              &copy; {new Date().getFullYear()} <span>&nbsp; | azszero®</span>
            </p>
          </Col>
          <Col xxm={6} style={{ textAlign: "right" }}>
            <p>
              <StyledLink to="/legal/conditionsofuse">
                Conditions of Use &nbsp; | &nbsp;
              </StyledLink>

              <StyledLink to="/legal/privacypolicy">Privacy Policy</StyledLink>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

const StyledLink = styled(Link)`
  text-decoration: none;
  color: dimgray;

  &:hover {
    text-decoration: none;
    color: white;
  }
`

export default Footer
